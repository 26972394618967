import CryptoJS from 'crypto-js';

const decryptToken = (encryptedToken: string, key: string) => {
  try {
    // Decode the URL-encoded token
    const urlDecoded = decodeURIComponent(encryptedToken);

    // Decrypt the token using AES decryption
    const decryptedBytes = CryptoJS.AES.decrypt(urlDecoded, key);

    // Convert the decrypted bytes to a UTF-8 string
    const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);

    return decryptedToken;
  } catch (error) {
    console.error('Error decrypting token:', error);
    return null;
  }
};

export default decryptToken;
